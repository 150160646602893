import { Flex, Heading, Button, Text, Box, Image } from "@chakra-ui/react";
import React from "react";
import {
  MAIN,
  MAIN_BACKGROUND,
  SECONDARY,
  SECONDARY_BACKGROUND,
} from "./colours";

function Section(props) {
  const BACKGROUND = props.secondary ? SECONDARY_BACKGROUND : MAIN_BACKGROUND;
  const COLOR = props.secondary ? SECONDARY : MAIN;

  return (
    <Flex
      id={props.title}
      bgColor={BACKGROUND}
      justifyItems={"center"}
      alignItems={"center"}
      maxW={"100vw"}
      py={"32"}
    >
        <Box px={{ base: "8", md: "10", lg: "24" }}>
          <Flex>
            <Box>
              <Heading
                color={COLOR}
                size={{ base: "2xl", lg: "3xl" }}
                lineHeight={"80px"}
              >
                {props.title}
              </Heading>
              <Text my="6" color={COLOR} w={{ base: "100%", lg: "70%" }} fontSize={'lg'}>
                {props.body}
              </Text>
              {props.children ? (
                props.children
              ) : (
                <Button
                  mt="8"
                  size="lg"
                  bg="transparent"
                  color={COLOR}
                  border={`2px solid ${COLOR}`}
                  borderRadius={"none"}
                  _hover={{ bg: COLOR, color: BACKGROUND }}
                  onClick={() =>
                    document
                      .getElementById("GET IN TOUCH")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Contact me
                </Button>
              )}
            </Box>
            <Image/>
          </Flex>
        </Box>
    </Flex>
  );
}

export default Section;
