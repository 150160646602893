import "./App.css";
import {
  ChakraProvider,
  Flex,
  Image,
  Heading,
  Text,
  Box,
} from "@chakra-ui/react";
import Section from "./Section";
import Header from "./Header";
import Footer from "./Footer";
import ContactForm from "./ContactForm";
import { MAIN, MAIN_BACKGROUND, SECONDARY } from "./colours";
import Slider from "react-slick";

function App() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <ChakraProvider>
      <Header />
      <Section
        title="LOCALLY SOURCED, SUSTAINABLE AND SEASONAL CATERING"
        body="Made by Harri. North Wales based."
      />

      <Section
        title="FOR HOLIDAYS"
        secondary
        body="Step away from your kitchen during your holiday – let me handle
                the cooking. Enjoy custom home-cooked meals just the way you
                like them. Whether you fancy dishes ready to pop in the oven or
                want me to handle everything from start to finish, so that you
                can spend a bit more time in the mountains or on the sand."
      />

      <Flex>
        <Box w={{ base: "100%", lg: "50%" }} bg={MAIN_BACKGROUND}>
          <Image src="/mountain.jpeg" alt="logo" p="0" m="0" />
        </Box>

        <Box
          display={{ base: "none", lg: "block" }}
          w="50%"
          bg={MAIN_BACKGROUND}
        >
          <Image src="/table.jpeg" alt="logo" p="0" m="0" />
        </Box>
      </Flex>

      <Section
        title="FOR SECRET DINNERS"
        body="For you and your loved one. We’ll throw you a secret dinner outdoors. We’ll check the forecast and choose the best location to set up a romantic dinner for two."
      />
      <Section
        title="FOR RETREATS"
        secondary
        body="I enjoy yoga, spirituality, writing, hiking and mindfulness—they're
        things I love to do in my spare time. There's nothing I love more
        than creating a safe space for people to come together, share
        skills, support each other, and just spread some love. Cooking for
        retreats is honestly one of my favourite ways to spend my time. I'm
        keen to whip up menus that are balanced, full of nutrients, and give
        everyone that energy boost they need to make the most of the
        retreat. Or maybe you want to host a retreat together? Definitely
        get in touch!"
      />

      <Flex>
        <Box w={{ base: "100%", lg: "50%" }} bg={MAIN_BACKGROUND}>
          <Image src="/me.jpeg" alt="logo" p="0" m="0" />
        </Box>

        <Box
          display={{ base: "none", lg: "block" }}
          w="50%"
          bg={MAIN_BACKGROUND}
        >
          <Image src="/steak.jpeg" alt="logo" p="0" m="0" />
        </Box>
      </Flex>

      <Section
        title="GET IN TOUCH"
        body="Drop me a line at to discuss menus, retreat ideas, special occasions or
            joining forces!"
      >
        <Text pb="4" color={MAIN}>
          Or email me at{" "}
          <a href="mailto:happyfigfoods@gmail.com">
            <strong>happyfigfoods@gmail.com</strong>
          </a>
        </Text>
        <ContactForm />
      </Section>
      <Section title="WHAT PEOPLE ARE SAYING" secondary>
        <Flex flexDir={"column"} color={SECONDARY} my="12">
          <Heading pb="4" size={"lg"}>
            “Happy Fig provided excellent service and professionalism at short
            notice to cater for a dinner while we were on holiday. Friendly and
            great food, with a wide selection to cater for different dietary
            requirements. Would be very happy to work with Harri again.”
          </Heading>
          <Text>- Katherin Talbot, Anglesey</Text>
        </Flex>

        <Flex flexDir={"column"} color={SECONDARY}>
          <Heading pb="4" size={"lg"}>
            “Happy Fig deliver a super quality, reliable catering to fit any
            budget. We are extremely satisfied with their service and would
            highly recommend them!”
          </Heading>
          <Text>- Stephen Firks, Bangor</Text>
        </Flex>

        <Flex flexDir={"column"} color={SECONDARY} mt="12">
          <Heading pb="4" size={"lg"}>
            “Harri has catered for us many times on a variety of occasions and
            has always been amazing.. nothing is too much trouble and even the
            simplest wholesome meals taste phenomenal! She is so approachable to
            ask for exactly what you need and I would recommend Happy Fig for
            any and every culinary event !”
          </Heading>
          <Text>- Debby Cavill, LLanberis</Text>
        </Flex>
      </Section>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
