import "./App.css";
import { Flex, Button, Image, Box } from "@chakra-ui/react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { MAIN, MAIN_BACKGROUND } from "./colours";

export default function Header() {
  return (
    <Flex
      h="10vh"
      justifyContent={"space-between"}
      alignItems={"center"}
      bgColor={MAIN_BACKGROUND}
      px="8"
      py="20"
    >
      <Image src="/logo.png" alt="logo" height={250} pt="16" />
      <Flex
        alignItems={"center"}
        fontSize={"40px"}
        color={MAIN}
        direction={{ base: "column", lg: "row" }}
      >
        <FaInstagram
          cursor={"pointer"}
          onClick={() =>
            window.open("https://www.instagram.com/happyfigfoods/?hl=en")
          }
        />
        <Box ml={{ base: "0", lg: "8" }} mt={{ base: "4", lg: "0" }}>
          <FaWhatsapp
            cursor={"pointer"}
            onClick={() => window.open("https://wa.me/447415455260")}
          />
        </Box>
        <Button
          size="lg"
          bg="transparent"
          color={MAIN}
          ml="14"
          display={{ base: "none", md: "block" }}
          border={`2px solid ${MAIN}`}
          borderRadius={"none"}
          _hover={{ bg: MAIN, color: MAIN_BACKGROUND }}
          onClick={() =>
            document
              .getElementById("GET IN TOUCH")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Contact me
        </Button>
      </Flex>
    </Flex>
  );
}
