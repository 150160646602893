import "./App.css";
import { Flex, Input, Textarea, Button, Text, Box } from "@chakra-ui/react";

import { MAIN, MAIN_BACKGROUND } from "./colours";

import emailjs from "@emailjs/browser";

import React, { useState } from "react";

export default function ContactForm() {
  React.useEffect(() => emailjs.init("BHPldWO3MwAQy6P6W"), []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await emailjs.send("service_fo5yqxl", "template_c89ux31", {
        name: name,
        recipient: email,
        message
      });
      alert("Your message has been sent");
      setName('')
      setEmail('')
      setMessage('')
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <Box>
      <Text mb="2" color={MAIN} value={name}>
        Name
      </Text>
      <Input
        bg="white"
        borderRadius={"none"}
        mb="3"
        focusBorderColor={MAIN}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Text mb="2" color={MAIN}>
        Email
      </Text>
      <Input
        bg="white"
        borderRadius={"none"}
        mb="3"
        focusBorderColor={MAIN}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Text mb="2" color={MAIN}>
        Message
      </Text>
      <Textarea
        bg="white"
        borderRadius={"none"}
        mb="6"
        focusBorderColor={MAIN}
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Flex>
        <Button
          size="lg"
          bg="transparent"
          color={MAIN}
          border={`2px solid ${MAIN}`}
          borderRadius={"none"}
          _hover={{ bg: MAIN, color: MAIN_BACKGROUND }}
          onClick={handleSubmit}
        >
          Contact me
        </Button>
      </Flex>
    </Box>
  );
}
