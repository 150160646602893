import "./App.css";
import { Heading, Flex, Button } from "@chakra-ui/react";
import { FaInstagram } from "react-icons/fa";
import { SECONDARY, SECONDARY_BACKGROUND } from "./colours";

export default function Footer() {
  return (
    <Flex justifyContent={"center"} py="10" bgColor={SECONDARY_BACKGROUND}>
      <Flex direction="column" alignItems={"center"}>
      <Button
          size="lg"
          bg="transparent"
          color={SECONDARY}
          border={`2px solid ${SECONDARY}`}
          borderRadius={"none"}
          _hover={{ bg: SECONDARY, color: SECONDARY_BACKGROUND }}
        >
          Terms and Conditions
        </Button>
        <Heading color={SECONDARY} size="md" my="8">
          #HAPPYFIG
        </Heading>
        <FaInstagram cursor={"pointer"} color={SECONDARY} size="35px" onClick={() => window.open("https://www.instagram.com/happyfigfoods/?hl=en")} />
      </Flex>
    </Flex>
  );
}
